<!--
<div class="text-sm font-medium text-gray-500">{{ labelText }}</div>
-->
<div class="elems mb-2" [ngClass]="{ 'elems-badge': viewType == 'badge', 'elems-text': viewType == 'text', 'elems-text-inline': viewType == 'text-inline' }">
    <div class="elem selected" *ngFor="let dp of selectedDataPoints">
        {{ dp.getValue() }}
        <i class="bi bi-x" (click)="unselectDataPoint(dp)"></i>
    </div>

    <div class="suggestion-container" *ngIf="featuredDataPoints.length > 0 && showFeaturedInline">
        <!--<span class="title">Suggestions:</span>-->
        <span class="elems-badge">
            <ng-container>
                <span class="elem" (click)="selectDataPoint(fdp.getDataPoint())" *ngFor="let fdp of featuredDataPoints">
                    <span class="content">
                        <span class="tip">
                            {{ fdp.getDataPointValue() }}
                            <span class="tiptext">{{ 'word.Suggestion' | translate }}</span>
                        </span>
                    </span>
                </span>
            </ng-container>
        </span>
    </div>
</div>

<!--
<div style="margin-top: 5px;">
    <input 
        class="add-item" 
        [(ngModel)]="sug" 
        type="text" 
        (keyup)="autoCompleteDataPoints()" 
        (focusin)="loadDataPoints(); openPreviewSuggestions()"  
        placeholder="Add..." />

    <div *ngIf="loadingDataPoints" class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>

    <div class="dropdown" *ngIf="filterDataPoints && filterDataPoints.length > 0 || (sug.length > 1 && !isInputMatchingAFilteredDataPoint())">
        <i class="close bi bi-x-lg" (click)="closePreviewSuggestions()"></i>
        <div class="items">
            <div class="item" *ngIf="sug.length == 0 && filterDataPoints.length < dataPoints.length">
                <div></div>
                <i>Type to search...</i>
            </div>

            <div class="item" [ngClass]="{ 'selected': isSelected(dp) }" (click)="selectDataPoint(dp)" *ngFor="let dp of filterDataPoints">
                <i class="bi bi-search"></i>
                <div>{{ dp.getValue() }}</div>
            </div>
        </div>
    </div>
</div>
-->

<button class="btn-outline-blue-xs flex gap-2" (click)="loadDataPoints(); openPreviewSuggestions()">
    {{ labelText }}
</button>

<div class="relative z-30" role="dialog" aria-modal="true" *ngIf="filterDataPoints && filterDataPoints.length > 0 || (sug.length > 1 && !isInputMatchingAFilteredDataPoint())">

    <!--
        Background backdrop, show/hide based on modal state.

        Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
        Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"

    -->
    <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"></div>

    <div class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
        <!--
            Command palette, show/hide based on modal state.

            Entering: "ease-out duration-300"
            From: "opacity-0 scale-95"
            To: "opacity-100 scale-100"
            Leaving: "ease-in duration-200"
            From: "opacity-100 scale-100"
            To: "opacity-0 scale-95"
        -->

        <div id="popupContainer" class="mx-auto max-w-xl transform rounded-xl bg-white p-2 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
            <input 
                [(ngModel)]="sug"
                (keyup)="autoCompleteDataPoints()" 
                (focusin)="loadDataPoints(); openPreviewSuggestions()"
                id="dpSearchField"
                type="text" 
                class="w-full rounded-md border-0 bg-gray-100 px-4 py-2.5 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm" 
                placeholder="Search..." 
                role="combobox" 
                aria-expanded="false" 
                aria-controls="options">

            <h2 class="mt-4 mb-2 px-3 text-xs font-semibold text-gray-500" id="label" *ngIf="labelText">{{ labelText }}</h2>
            <!-- Results, show/hide based on command palette state. -->
            <ul class="-mb-2 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800 space-y-1" id="dpOptions" role="listbox">
                <!-- Active: "bg-indigo-600 text-white" -->
                <li 
                    id="dpOption-{{ dp.getId() }}"
                    class="cursor-pointer select-none rounded-md px-4 py-2" 
                    role="option" 
                    tabindex="-1" 
                    [ngClass]="{ 'bg-blue-100': isSelected(dp) }"
                    (click)="selectDataPoint(dp)"
                    *ngFor="let dp of filterDataPoints">{{ dp.getValue() }}</li>
            </ul>

            <!-- Empty state, show/hide based on command palette state. -->
            <div class="py-14 px-4 text-center sm:px-14" *ngIf="filterDataPoints && filterDataPoints.length == 0">
                <svg class="mx-auto h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                </svg>
                <p class="mt-4 text-sm text-gray-900">No options found using that search term.</p>

            </div>
        </div>
    </div>
</div>

