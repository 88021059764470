import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { GeneralModule } from '../_shared_modules/general/general.module';
import { ModalModule } from '../modal/modal.module';
import { CollectionSharedModule } from '../_shared_modules/collection-shared/collection-shared.module';
import { ServiceModule } from '../service/service.module';
import { TranslateModule } from '@ngx-translate/core';

import { DataPointSelectComponent } from './data-point-select/data-point-select.component';
import { DataPointFilterComponent } from './data-point-filter/data-point-filter.component';
import { DataPointFilterBtnComponent } from './data-point-filter-btn/data-point-filter-btn.component';
import { DataPointAutocompleteComponent } from './data-point-autocomplete/data-point-autocomplete.component';
import { DataPointUpdateComponent } from './data-point-update/data-point-update.component';


@NgModule({
  declarations: [
    DataPointSelectComponent,
    DataPointFilterComponent,
    DataPointAutocompleteComponent,
    DataPointFilterBtnComponent,
    DataPointUpdateComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GeneralModule,
    ModalModule,
    CollectionSharedModule,
    ServiceModule,
    TranslateModule.forChild({
        extend: true
    })
  ],
  exports: [
    DataPointSelectComponent,
    DataPointFilterComponent,
    DataPointFilterBtnComponent,
  ]
})
export class CollectionModule { }
