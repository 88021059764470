import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';

import { CollectionService } from '../../_services/collection.service';

import { DataPoint, FeaturedDataPoint, RelatedDataPoint } from '../../_models/collection';

@Component({
  selector: 'app-data-point-filter-btn',
  templateUrl: './data-point-filter-btn.component.html',
  styleUrls: ['./data-point-filter-btn.component.scss']
})
export class DataPointFilterBtnComponent implements OnInit {

    @ViewChild('upgradeModal') upgradeModal;

    @Input() dataTypeKey: string;
    //@Input() multiselect: boolean = true;
    @Input() viewType: string = "badge"; // text

    @Input() set preSelectedDataPoint(s: string) {

        if (s) {

            this.collectionService.getDataPoint(this.dataTypeKey, s).subscribe(
                dp => {
                    this.selectedDataPoints.push(dp);
                    this.selected.emit(this.selectedDataPoints);
                },
                error => {
                    console.log("Error fetching dp");
                }
            );
        }

    }

    @Input() set preSelectedDataPoints(rdps: RelatedDataPoint[] | undefined) {

        if (rdps) {
            for (let rdp of rdps) {
                if (rdp.getDataTypeKey() == this.dataTypeKey) {
                    this.selectedDataPoints.push(rdp.getDataPoint());
                }
            }

            this.selected.emit(this.selectedDataPoints);
        }

    }

    @Input() set allFeaturedDataPoints(fdps: FeaturedDataPoint[]) {
        this.featuredDataPoints = [];
        for (let fdp of fdps) {
            if (fdp.getDataTypeKey() == this.dataTypeKey) {
                this.featuredDataPoints.push(fdp);
            }
        }

        this.featuredDataPoints.sort(this.sortByFeaturedOrder)

        // clone / keep original record
        this._featuredDataPoints = Object.create(this.featuredDataPoints);

        //this.rmSelectedFromFeatured();
        this.updateFeaturedDataPoints();
    }

    @Input() showAllSuggestions: boolean = false;
    @Input() showFeaturedInline: boolean = false;
    //@Input() showAddInput: boolean = true;

    //showInputField: boolean = true;

    @Input() filterSuggestionMinChars: number = 1;
    @Input() singleSelect: boolean = false;
    @Input() sortBy: string;
    @Input() limitResults: number;

    label: any;

    @Input() set labelJson(l: any) {
        this.label = l;
        this.getLabelText('en');
    }

    @Input() labelText: string;

    @Input() companyId: number;

    // only allow verified users to access this filter
    @Input() onlyAllowVerified: boolean = false;

    // get if the user has access (verified business)
    @Input() set setHasAccess(a: boolean) {
        this.hasAccess = a;
    }

    @Input() disabled: boolean = false;

    @Output() selected = new EventEmitter<(DataPoint | null)[]>();

    selectedDataPoints: (DataPoint | null)[] = [];
    dataPoints: DataPoint[];

    _featuredDataPoints: FeaturedDataPoint[] = []; // original clone
    featuredDataPoints: FeaturedDataPoint[] = [];

    filterDataPoints: DataPoint[] = [];

    sug: string = '';

    loadingDataPoints: boolean = false;

    hasAccess: boolean = false;

    constructor(
        private collectionService: CollectionService,
    ) { }

    ngOnInit(): void {
    }

    // clicked outside => close
    @HostListener('document:mousedown', ['$event']) onGlobalClick(event): void {
        if (
                event.target.id != 'dpSearchField' &&
                event.target.id != 'dpOptions' &&
                !event.target.id.startsWith('dpOption') &&
                event.target.id != 'label' &&
                event.target.id != 'popupContainer'
           ) {
            this.closePreviewSuggestions();
        }
    }

    // close on escape
    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.closePreviewSuggestions();
    }

    isSelected(dp: DataPoint) {
        if (this.selectedDataPoints) {
            //const found = this.relatedDataPoints.find(rdp => rdp.data_point == dp.getId() && this.dataTypeKey == rdp.data_type_key)
            const found = this.selectedDataPoints.find(sdp => sdp?.getId() == dp.getId())
            if (found) {
                return true;
            }
        }
        return false;
    }

    /*setShowInputField() {
        if (this.dataPoints && this._featuredDataPoints) {
            console.log("dps", this.dataPoints.length);
            console.log("fdps", this._featuredDataPoints.length);
        }
        if (this.dataPoints && this._featuredDataPoints && this.dataPoints.length == this._featuredDataPoints.length) {
            this.showInputField = false;
        } else {
            this.showInputField = true;
        }
    }*/

    loadDataPoints() {
        if (!this.dataPoints || (this.dataPoints && this.dataPoints.length == 0)) {
            this.getDataPoints();
        }
    }

    acStartTime: any;
    acElapsedTime: any;

    autoCompleteDataPoints() {

        this.acStartTime = Date.now();

        // check time between keystrokes, if more than 800 milisec, get data points
        setTimeout(() => {
            this.acElapsedTime = Date.now() - this.acStartTime;
            if (this.acElapsedTime >= 800) {
                this.acStartTime = 0;
                this.acElapsedTime = 0;

                this.getDataPoints(this.sug);
            }
        }, 800);

    }

    getDataPoints(autoCompText?: string) {
        this.loadingDataPoints = true;

        this.collectionService.getDataPoints(this.dataTypeKey, this.sortBy, this.limitResults, autoCompText).subscribe(
            dps => {
                this.dataPoints = dps;

                this.filterSuggestions();

                this.openPreviewSuggestions();

                this.loadingDataPoints = false;
            },
            error => {
                console.log("Error fetching dps");
            }
        );
    }

    selectDataPoint(dp: DataPoint) {

        if (this.singleSelect) {
            this.selectedDataPoints = [dp];
            this.closePreviewSuggestions();
        } else {
            const index = this.selectedDataPoints.findIndex(sdp => sdp?.getId() == dp.getId());
            if (index == -1) {
                this.selectedDataPoints.push(dp);
            }
        }

        this.rmSelectedFromFeatured();

        this.selected.emit(this.selectedDataPoints);
    }

    unselectDataPoint(dp: DataPoint | null) {
        const index = this.selectedDataPoints.findIndex(sdp => sdp?.getId() == dp?.getId());
        if (index > -1) {
            this.selectedDataPoints.splice(index, 1);
        }

        this.selected.emit(this.selectedDataPoints);
    }

    updateFeaturedDataPoints() {
        this.featuredDataPoints = Object.create(this._featuredDataPoints); // clone without reference
        this.rmSelectedFromFeatured();
    }

    // remove selected DataPoints from featured DataPoints
    rmSelectedFromFeatured() {
        for (let dp of this.selectedDataPoints) {
            const index = this.featuredDataPoints.findIndex(fdp => fdp.getDataPointId() == dp?.getId());
            if (index > -1) {
                this.featuredDataPoints.splice(index, 1);
            }
        }
    }

    isInputMatchingAFilteredDataPoint() {
        const found = this.filterDataPoints.find(dp => dp.getValue().toLowerCase() == this.sug.toLowerCase())
        if (found)
            return true;
        return false;
    }

    sortByValue( a, b ) {
        if ( a.value['en'] < b.value['en'] ){
            return -1;
        }
        if ( a.value['en'] > b.value['en'] ){
            return 1;
        }
        return 0;
    }

    sortByFeaturedOrder( a, b ) {
        if ( a.getOrder() < b.getOrder() ){
            return -1;
        }
        if ( a.getOrder() > b.getOrder() ){
            return 1;
        }
        return 0;
    }

    filterSuggestions() {
        if (this.sug == '') {
            this.filterDataPoints = [];

        } else {
            if (this.sug.length >= this.filterSuggestionMinChars) {
                if (this.dataPoints)
                    this.filterDataPoints = this.searchFromArray(this.dataPoints, this.sug);
            }
        }

    }

    searchFromArray(arr, text) {
        let matches: any[] = [], i;
        for (i = 0; i < arr.length; i++) {
            let val = arr[i].value['en'];
            // if only one letter look for exact match
            if (text.length == 1) {
                if (val.toLowerCase() == text.toLowerCase())
                    matches.push(arr[i]);

            // if more that one letter look for the pattern
            } else {
                if (val.toLowerCase().includes(text.toLowerCase()))
                    matches.push(arr[i]);
            }
        }
        return matches;
    };

    openPreviewSuggestions() {

        if (this.onlyAllowVerified && !this.hasAccess) {
            this.upgradeModal.open();

        } else if (this.dataPoints && this.filterDataPoints && this.filterDataPoints.length == 0) {
            if (this.showAllSuggestions || +this.limitResults == 0) {
                this.filterDataPoints = this.dataPoints;
            } else {
                this.filterDataPoints = this.dataPoints.slice(0, 10);
            }
            // NOTE replaced by backend sorting most popular dp
            //if (this.filterDataPoints)
            //    this.filterDataPoints.sort(this.sortByValue);
        }
    }

    closePreviewSuggestions() {
        this.filterDataPoints = [];
        this.sug = '';
        this.loadingDataPoints = false;
    }

    /**
     * Format
     * {
        "key": "business_function",
        "label": {
            "en": {
                "editing": "What are your preferred business functions?",
                "viewing": "Preferred business function"
            },
            "sv": {
                "editing": "Vilka funktioner på ett företag vill du jobba med?",
                "viewing": "Föredragna affärsfunktioner"
            }
        }
    }
    */
    getLabelText(locale: string = 'en') {
        if (this.label) {
            try {
                this.labelText = this.label[locale]['viewing'];
            } catch {
                this.labelText = '';
            }
        }
        return null;
    }

}
