import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AlertService } from '../../_services/alert.service';
import { CollectionService } from '../../_services/collection.service';

import { AccountUser } from '../../_models/user';
import { DataPoint, DataType } from '../../_models/collection';

@Component({
  selector: 'app-data-point-update',
  templateUrl: './data-point-update.component.html',
  styleUrls: ['./data-point-update.component.scss']
})
export class DataPointUpdateComponent implements OnInit {

    dataPoint: DataPoint | null;
    dataTypes: DataType[] = [];

    @Input() set setDataPoint(dp: DataPoint | null) {
        this.dataPoint = dp;
        if (!dp) {
            return;
        }
        this.dataTypes = dp.getDataTypes();

        this.form.controls['id'].setValue(dp.id);
        this.form.controls['value_en'].setValue(dp.getValue());
        this.form.controls['data_types'].setValue(dp.data_types);
        this.form.controls['verified'].setValue(dp.isVerified());
    }

    @Input() loggedInAccountUser: AccountUser | undefined | null;

    @Output() updatedDataPoint = new EventEmitter<boolean>();

    form: FormGroup;


    constructor(
        private collectionService: CollectionService,
        private alertService: AlertService,
        private formBuilder: FormBuilder,
    ) {
        this.form = this.formBuilder.group({
            id: ['', Validators.required],
            value_en: ['', Validators.required],
            data_types: [''],
            verified: [new FormControl(false)],
        });
    }

    ngOnInit(): void {
    }

    isStaff() {
        if (this.loggedInAccountUser && (this.loggedInAccountUser.isAdmin() || this.loggedInAccountUser.isManager())) {
            return true;
        }
        return false;
    }

    update() {
        if (this.form.invalid || !this.isStaff()) {
            return;
        }

        this.collectionService.updateDataPoint(this.form.value).subscribe(
            data => {
                this.updatedDataPoint.emit(true);
                //this.dataPoint.value['en'] = this.form.value.value_en;
                //this.dataPoint.data_types = this.form.value.data_types;
                //this.dataPoint.verified = this.form.value.verified;
                this.alertService.success("Data point updated");
            },
            error => {
                this.alertService.error("Error updating data point");
            }
        );
    }

    removeDataType(dtId: number) {
        let data = {
            id: this.dataPoint?.id,
            data_type_id: dtId,
        };

        if (confirm("Are you sure you want to remove this data type from this data point?")) {

            this.collectionService.removeDataTypeFromDataPoint(data).subscribe(
                data => {
                    this.updatedDataPoint.emit(true);
                    this.alertService.success("Data point updated");

                    // remove the data type from the list
                    this.dataTypes = this.dataTypes.filter(dt => dt.id != dtId);
                    if (this.dataPoint) {
                        this.dataPoint.data_types = this.dataTypes;
                    }
                },
                error => {
                    this.alertService.error("Error updating data point");
                }
            );

        }

    }

}
