<div>
    <a [routerLink]="['/section/' + _sectionKey + '/' + encodeString(dpName)]">
        <img *ngIf="dp.info_card_image" [src]="dp.info_card_image" loading="lazy" class="aspect-[3/2] w-full rounded-lg object-cover mb-2">
        <div class="flex items-center">
            <div>
                <h3 class="text-md font-medium tracking-tight text-gray-900">
                    {{ dpName | titlecase }}
                </h3>
            </div>
        </div>
    </a>
</div>

