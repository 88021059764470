@defer {
<div>
    <div class="flex gap-2 items-center">
        <div class="text-sm font-medium text-gray-500" *ngIf="labelText && showLabel">{{ labelText }}</div>
        <div class="cursor-pointer" *ngIf="isStaff()" (click)="openDataPointStaffModal()"><i class="bi bi-gear"></i></div>
    </div>

    <div [ngClass]="{ 'add-dp-container': dataPointCount != (featuredDataPoints.length + selectedDataPoints.length) }" *ngIf="canEdit()">
        <div *ngIf="canAddDataPoint || dataPointCount != (featuredDataPoints.length + selectedDataPoints.length)">
            <app-data-point-autocomplete
                [accountUserId]="accountUserId"
                [companyId]="companyId"
                [jobPost]="jobPost"
                [jobKey]="jobKey"
                [hubId]="hubId"
                [dataTypeKey]="dataTypeKey"
                [showAllSuggestions]="showAllSuggestions"
                [filterSuggestionMinChars]="filterSuggestionMinChars"
                [canAddDataPoint]="canAddDataPoint"
                [singleSelect]="singleSelect"
                [dataPointCount]="dataPointCount"
                [placeholder]="placeholder"
                [sortBy]="sortBy"
                [limitResults]="limitResults"
                [allFeaturedDataPoints]="featuredDataPoints"
                [allSelectedDataPoints]="selectedDataPoints"
                (newJobKey)="addDataPoint($event)"
                (dataPointAdded)="addInstantRdp($event); getRelatedDataPoints();"
                (triggerToggle)="addRelatedDataPoint($event)"
            ></app-data-point-autocomplete>
        </div>

        <div class="suggestion-container" *ngIf="featuredDataPoints.length > 0 && showFeaturedInline && canEdit()">
            <!--<span class="title">Suggestions:</span>-->
            <span class="elems-badge">
                <ng-container>
                    <button class="elem" (click)="addRelatedDataPoint(fdp.getDataPoint())" *ngFor="let fdp of featuredDataPoints" [disabled]="loading">
                        <span class="content">
                            <span class="tip">
                                {{ fdp.getDataPointValue() }}
                                <span class="tiptext">{{ 'word.Suggestion' | translate }}</span>
                            </span>
                        </span>
                    </button>
                </ng-container>
            </span>
        </div>
    </div>

    <!-- badge -->
    <div *ngIf="!_editing && viewType == 'badge'" class="elems-badge">
        <div class="elem selected" *ngFor="let rdp of relatedDataPoints">
            <span class="content">
                <span *ngIf="getDataPoint(rdp)">
                    {{ getDataPointValue(rdp) }}
                </span>

                <span *ngIf="getSubDataPoint(rdp)">
                    {{ getSubDataPointValue(rdp) }}
                </span>

                <span *ngIf="rdp.getStartDate() || rdp.getEndDate()">
                    {{ getFullYear(rdp.getStartDate()) }}
                    <span *ngIf="rdp.getStartDate() || rdp.getEndDate()"> &ndash; </span>
                    <span *ngIf="rdp.getEndDate()">{{ getFullYear(rdp.getEndDate()) }}</span>
                    <span *ngIf="rdp.getStartDate() && !rdp.getEndDate()"><span class="present-text">Present</span></span>
                </span>

            </span>

            <div *ngIf="showRelatedExperience && rdp.getExperience()">
                <span class="point-viewing tip">
                    {{ rdp.getExperience() }}<span class="tiptext">{{ rating[rdp.getExperience()] }}</span>
                </span>
            </div>

        </div>
    </div>

    <!-- text -->
    <div *ngIf="!_editing && (viewType == 'text' || viewType == 'text-inline')" [ngClass]="{ 'elems-text': viewType == 'text', 'elems-text-inline': viewType == 'text-inline' }">
        <div class="elem selected" *ngFor="let rdp of relatedDataPoints">
            <span class="content">
                <span class="text-base">
                    {{ getDataPoint(rdp)?.getValue() }}
                </span>

                <span class="text-base" *ngIf="getSubDataPoint(rdp) && showRelatedSubDataType">
                    <!--
                    {{ getSubDataPoint(rdp).getValue() }}
                    -->
                    <a class="link" *ngIf="rdp.pageSlug" [routerLink]="['/' + rdp.pageSlug]" target="_blank">{{ getSubDataPointValue(rdp) }}</a>
                    <span *ngIf="!rdp.pageSlug">{{ getSubDataPointValue(rdp) }}</span>
                </span>

                <span class="text-base" *ngIf="rdp.getStartDate() || rdp.getEndDate()">
                    {{ getFullYear(rdp.getStartDate()) }}
                    <span *ngIf="rdp.getStartDate() || rdp.getEndDate()"> &ndash; </span>
                    <span *ngIf="rdp.getEndDate()">{{ getFullYear(rdp.getEndDate()) }}</span>
                    <span *ngIf="rdp.getStartDate() && !rdp.getEndDate()"><span class="present-text">Present</span></span>
                </span>

            </span>

            <div *ngIf="showRelatedExperience && rdp.getExperience()">
                <span class="point-viewing tip">
                    {{ rdp.getExperience() }}<span class="tiptext">{{ rating[rdp.getExperience()] }}</span>
                </span>
            </div>

        </div>
    </div>

    <!-- text-plain -->
    <span *ngIf="!_editing && viewType == 'text-plain'">
        <span *ngFor="let rdp of relatedDataPoints">
            {{ getDataPointValue(rdp) }}
        </span>
    </span>

    <div class="editing-container" *ngIf="_editing">
        <div class="editing-box" *ngFor="let rdp of relatedDataPoints">

            <div class="flex gap-3">
                <span class="grow font-medium">{{ getDataPointValue(rdp) }}</span>

                <span class="flex-none">
                    <span *ngIf="rdp.getId()" class="tip">
                        <i class="bi bi-x cursor-pointer text-gray-500" (click)="removeRelatedDataPoint(rdp.getId())" *ngIf="canEdit()"></i>
                        <span class="tiptext">{{ 'word.Remove' | translate }}</span>
                    </span>

                    <span class="ml-4 cursor-pointer text-gray-500" *ngIf="isStaff()" (click)="updateDataPoint(getDataPoint(rdp))"><i class="bi bi-gear"></i></span>
                </span>

            </div>

            <div class="points" *ngIf="showRelatedExperience">
                <span class="point tip" [ngClass]="{'point-selected': rdp.getExperience() == 1}" (click)="updateRdpExperience(rdp.getId(), 1)">1<span class="tiptext">{{ rating[1] }}</span></span>
                <span class="point tip" [ngClass]="{'point-selected': rdp.getExperience() == 2}" (click)="updateRdpExperience(rdp.getId(), 2)">2<span class="tiptext">{{ rating[2] }}</span></span>
                <span class="point tip" [ngClass]="{'point-selected': rdp.getExperience() == 3}" (click)="updateRdpExperience(rdp.getId(), 3)">3<span class="tiptext">{{ rating[3] }}</span></span>
                <span class="point tip" [ngClass]="{'point-selected': rdp.getExperience() == 4}" (click)="updateRdpExperience(rdp.getId(), 4)">4<span class="tiptext">{{ rating[4] }}</span></span>
                <span class="point tip" [ngClass]="{'point-selected': rdp.getExperience() == 5}" (click)="updateRdpExperience(rdp.getId(), 5)">5<span class="tiptext">{{ rating[5] }}</span></span>
            </div>

            <div *ngIf="rdp.getId() && showRelatedSubDataType && subDataTypeKey">
                <span class="flex gap-2 items-center" *ngIf="getSubDataPoint(rdp)">
                    <!--<span *ngIf="subDataTypeKey == 'company'">at</span>
                    <span *ngIf="subDataTypeKey != 'company'">&nbsp;&nbsp;&nbsp;&nbsp;</span>-->
                    <a class="link" *ngIf="rdp.pageSlug" [routerLink]="['/' + rdp.pageSlug]" target="_blank">{{ getSubDataPointValue(rdp) }}</a>
                    <span *ngIf="!rdp.pageSlug">{{ getSubDataPointValue(rdp) }}</span>

                    <span *ngIf="!rdp.pageSlug">
                        <svg class="w-5 h-5 cursor-pointer" (click)="inviteCompanyModal.open()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                        </svg>
                        <app-modal #inviteCompanyModal [size]="'md'">
                            <app-company-invite
                                [fromUserProfile]="true"
                                [useInputName]="true"
                                [companyName]="getSubDataPointValue(rdp)"
                                *ngIf="inviteCompanyModal.isOpen()"></app-company-invite>
                        </app-modal>
                    </span>
                </span>

                <app-data-point-autocomplete
                        *ngIf="!getSubDataPoint(rdp)"
                        [accountUserId]="accountUserId"
                        [companyId]="companyId"
                        [hubId]="hubId"
                        [dataTypeKey]="subDataTypeKey"
                        [parentDataTypeKey]="dataTypeKey"
                        [showAllSuggestions]="showAllSuggestions"
                        [filterSuggestionMinChars]="filterSuggestionMinChars"
                        [canAddDataPoint]="canAddDataPoint"
                        [singleSelect]="singleSelect"
                        [dataPointCount]="dataPointCount"
                        [placeholder]="placeholder"
                        [sortBy]="sortBy"
                        [limitResults]="limitResults"
                        [allFeaturedDataPoints]="featuredDataPoints"
                        [allSelectedDataPoints]="selectedDataPoints"
                        (dataPointAdded)="updateRdpSubDataPoint(rdp.getId(), $event)"
                        (triggerToggle)="updateRdpSubDataPoint(rdp.getId(), $event)"
                        ></app-data-point-autocomplete>
            </div>

            <div *ngIf="showRelatedStartDate">
                <span class="tip">
                    {{ 'word.Start' | translate }}:
                    <span class="tiptext">{{ 'Ex. 2018' | translate }}</span>
                </span>
                <input class="date-field" type="text" style="width: 55px; margin-top: 5px;" [value]="getFullYear(rdp.getStartDate())" placeholder="{{ 'word.Year' | translate }}" (change)="updateRdpDate(rdp.getId(), 'start_date', $event)">
            </div>

            <div *ngIf="showRelatedEndDate">
                {{ 'word.End' | translate }}:
                <input class="date-field" type="text" style="width: 55px; margin-top: 5px;" [value]="getFullYear(rdp.getEndDate())" placeholder="{{ 'word.Year' | translate }}" title="{{ 'Leave blank if you are still present' | translate }}" (change)="updateRdpDate(rdp.getId(), 'end_date', $event)">
            </div>

        </div>
    </div>


</div>
}


<app-modal #dpModal [size]="'md'">

    <div *ngIf="dpModal.isOpen() && isStaff()">

        <div class="text-lg font-medium text-gray-500 mb-4" *ngIf="labelText">{{ labelText }}</div>

        <input
            class="add-item"
            [(ngModel)]="sugStaff"
            [placeholder]="placeholder"
            type="text"
            (keyup)="autoCompleteDataPointsForStaff()" />

        <button type="button" class="btn-blue-sm ml-2" (click)="createDataPoint(); autoCompleteDataPointsForStaff();">Add</button>
        <button type="button" class="btn-blue-sm ml-4" (click)="mergeSelectedDataPoints()">Merge</button>
        <button type="button" class="btn-blue-sm ml-4" (click)="openDataTypeStaffModal()">Edit DataTypes</button>

        <div *ngIf="loading" class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>

        <ul role="list" class="divide-y divide-gray-100">
            <li *ngFor="let dp of dataPointsForStaff" class="flex justify-between items-center gap-x-6 px-2 py-3 hover:bg-gray-50">
                <div class="flex gap-x-2">
                    <input type="checkbox" class="form-checkbox text-blue-600" (change)="toggleToMergeDataPoint(dp)" />
                    <div class="min-w-0 flex-auto">
                        <p class="text-sm">
                            {{ dp.getValue() }}
                            <span *ngIf="!dp.isVerified()" class="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ml-3">Unverified</span>
                        </p>
                        <div class="text-xs text-gray-500 flex flex-wrap gap-1 mt-1">
                            <span *ngFor="let dtKey of dp.getDataTypeKeys()">
                                {{ dtKey }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="flex items-center gap-x-4">
                    <div class="flex flex-col items-end">
                        <span class="btn-blue-sm" *ngIf="isStaff()" (click)="updateDataPoint(dp)"><i class="bi bi-gear"></i></span>
                    </div>
                </div>
            </li>
        </ul>

    </div>

</app-modal>


<app-modal #updateDPModal [size]="'md'">
    <app-data-point-update
        *ngIf="updateDPModal.isOpen() && isStaff()"
        [loggedInAccountUser]="accountUser"
        [setDataPoint]="selectedDpToUpdate"
        (updatedDataPoint)="getRelatedDataPoints()"></app-data-point-update>
</app-modal>


<app-modal #editDataTypeModal [size]="'md'" (closed)="getDataPointsForStaff()">
    <div *ngIf="editDataTypeModal.isOpen() && isStaff()">
        <p class="mb-4 text-base font-medium">
            Selected Data Points
        </p>
        <ul role="list" class="divide-y divide-gray-100">
            <li *ngFor="let dp of selectedDataPointsByStaff" class="flex justify-between items-center gap-x-6 px-2 py-3 hover:bg-gray-50">
                <div class="min-w-0 flex-auto">
                    <div class="text-sm">
                        {{ dp.getValue() }}
                        <span *ngIf="!dp.isVerified()" class="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ml-3">Unverified</span>
                    </div>
                    <div class="text-xs text-gray-500 flex flex-wrap gap-1 mt-1">
                        <span *ngFor="let dtKey of dp.getDataTypeKeys()">
                            {{ dtKey }}
                        </span>
                    </div>
                </div>
            </li>
        </ul>

        <p class="my-4 text-base font-medium">
            Data Types
        </p>
        <ul role="list" class="divide-y divide-gray-100">
            <li *ngFor="let dt of dataTypes" class="flex justify-between items-center gap-x-4 px-2 py-3 hover:bg-gray-50">
                <div class="grow text-sm cursor-pointer">
                    {{ dt.getKey() }}
                </div>
                <button type="button" class="flex-none btn-blue-sm" (click)="addDataTypeToDataPoints(dt)">Add</button>
                <button type="button" class="flex-none btn-blue-sm" (click)="removeDataTypeFromDataPoints(dt)">Remove</button>
            </li>
        </ul>
    </div>
</app-modal>
