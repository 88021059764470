import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';


import { DataPointCardComponent } from '../../collection/data-point-card/data-point-card.component';


@NgModule({
  declarations: [
      DataPointCardComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    DataPointCardComponent
  ]
})
export class CollectionSharedModule { }
