<input
    *ngIf="showAddInput"
    class="w-full text-base"
    [(ngModel)]="sug"
    [placeholder]="placeholder"
    type="text"
    (keyup)="autoCompleteDataPoints()"
    (focusin)="showMobileFooter(false); loadDataPoints(); openPreviewSuggestions()"
    (focusout)="showMobileFooter(true)"
    (keydown.enter)="onEnter($event)" />

<div *ngIf="loading" class="spinner-border spinner-border-sm" role="status">
    <span class="visually-hidden">Loading...</span>
</div>

<div class="dropdown max-h-[500px] overflow-y-auto" *ngIf="filterDataPoints && filterDataPoints.length > 0 || (canAddDataPoint && sug.length > 1 && !isInputMatchingAFilteredDataPoint())">
    <i class="close bi bi-x-lg" (click)="closePreviewSuggestions()"></i>
    <div class="items">
        <div class="item" *ngIf="sug.length == 0 && filterDataPoints.length < dataPoints.length">
            <div></div>
            <div class="text-xs">
                <i class="bi bi-arrow-up-short" style="margin-right: 8px;"></i>
                <i>Type to search...</i>
            </div>
        </div>

        <div class="item text-sm" (click)="addDataPoint()" *ngIf="canAddDataPoint && sug.length > 0 && !isInputMatchingAFilteredDataPoint()">
            <i class="bi bi-plus"></i>
            <div>{{ sug }}</div>
        </div>

        <div class="item text-sm" [ngClass]="{ 'selected': isSelected(dp) }" (click)="triggerToggleDataPoint(dp)" *ngFor="let dp of filterDataPoints">
            <i class="bi bi-search"></i>
            <div>{{ dp.getValue(languageCode) }}</div>
        </div>
    </div>
</div>
