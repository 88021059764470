import { Component, OnInit, Input } from '@angular/core';

import { CollectionService } from '../../_services/collection.service';

import { DataPointInfo } from '../../_models/collection';

@Component({
  selector: 'app-data-point-card',
  templateUrl: './data-point-card.component.html',
  styleUrls: ['./data-point-card.component.scss']
})
export class DataPointCardComponent {

    // city, country, industry
    _sectionKey: string | null;

    @Input() set sectionKey(sectionKey: string | null) {
        this._sectionKey = sectionKey;
    }
    @Input() dp: any;
    @Input() dpName: string;

    info: DataPointInfo[];

    imageUrl: string;

    constructor(
        private collectionService: CollectionService
    ) { }

    ngOnInit(): void {

        this.collectionService.getDataPointInfo(this.dpName).subscribe(
            data => {
                this.info = data;

                for (let i of this.info) {
                    if (i.type == 'img') {
                        this.imageUrl = i.getInfo();
                    }
                }
            },
            error => {
            }

        );

    }

    // url encode string function
    encodeString(str: string) {
        return encodeURIComponent(str.toLowerCase());
    }

}
