import { Component, OnInit, Input, HostListener, Output, EventEmitter, ViewChild } from '@angular/core';

import { AccountService } from '../../_services/account.service';
import { AlertService } from '../../_services/alert.service';
import { CollectionService } from '../../_services/collection.service';
import { CompanyService } from '../../_services/company.service';

import { AccountUser } from '../../_models/user';
import { DataPoint, DataType, FeaturedDataPoint, RelatedDataPoint, GroupDataType } from '../../_models/collection';

@Component({
  selector: 'app-data-point-select',
  templateUrl: './data-point-select.component.html',
  styleUrls: ['./data-point-select.component.scss']
})
export class DataPointSelectComponent implements OnInit {

    @ViewChild('updateDPModal') updateDPModal;
    @ViewChild('dpModal') dpModal;
    @ViewChild('editDataTypeModal') editDataTypeModal;
    @ViewChild('inviteCompanyModal') inviteCompanyModal;

    _groupDataType: GroupDataType;
    //_relatedDataPoint: RelatedDataPoint;

    @Input() set groupDataType(gdt: GroupDataType) {
        this._groupDataType = gdt;

        this.dataTypeKey = gdt.getDataTypeKey();
        this.subDataTypeKey = gdt.getSubDataTypeKey();
        this.canAddDataPoint = gdt.getCanAddDataPoint();
        this.dataPointCount = gdt.getDataPointCount();
        this.singleSelect = gdt.isSingleSelect();
        this.duplicatesAllowed = gdt.isDuplicateAllowed();
        this.viewType = gdt.showViewType();
        this.sortBy = gdt.getSortBy();
        this.limitResults = gdt.getLimitResults();
        this.showFeaturedInline = gdt.showFeatured();
        this.showRelatedSubDataType = gdt.showRelatedSubDataType();
        this.showRelatedExperience = gdt.showRelatedExperience();
        this.showRelatedStartDate = gdt.showRelatedStartDate();
        this.showRelatedEndDate = gdt.showRelatedEndDate();
        this.filterSuggestionMinChars = gdt.getSuggestionMinChars();
    }

    @Input() set setRelatedDataPoints(rdps: RelatedDataPoint[]) {
        this.relatedDataPoints = [];
        this.selectedDataPoints = [];

        if (rdps) {
            for (let rdp of rdps) {
                if (rdp.getDataTypeKey() == this.dataTypeKey) {
                    this.relatedDataPoints.push(rdp);
                    if (!this.duplicatesAllowed) {
                        // dont select a data point if duplicates are allowed
                        this.selectedDataPoints.push(rdp.getDataPoint());
                    }

                    // if the sub data point is a company check if the name exists
                    // we use this to check if the company should be invited or not
                    if (this.subDataTypeKey == 'company') {
                        if (rdp.getSubDataPoint() && rdp.getSubDataPoint()['value'] && rdp.getSubDataPoint()['value']['en']) {
                            this.getCompanyExists(rdp.getSubDataPoint()['value']['en'], rdp);
                        }
                    }
                }
            }
            this.relatedDataPointCount.emit(this.relatedDataPoints.length);
        }
        //console.log('related DPS', this.relatedDataPoints);
        //console.log('selected', this.selectedDataPoints);

        //this.rmSelectedFromFeatured();
        this.updateFeaturedDataPoints();
    }


    @Input() dataTypeKey: string;
    @Input() subDataTypeKey: string;
    //@Input() multiselect: boolean = true;
    @Input() viewType: string = "badge"; // text, text-plain

    _editing: boolean = true;

    @Input() set editing(e: boolean) {
        this._editing = e;
        this.getLabelText('en');
    }

    accountUser: AccountUser | undefined | null;

    // related
    @Input() accountUserId: number | undefined | null;
    @Input() companyId: number;
    @Input() jobPost: boolean = false;
    @Input() jobKey: string | null;
    @Input() projectId: number;
    @Input() hubId: number;

    @Input() set setFeaturedDataPoints(fdps: FeaturedDataPoint[]) {
        this.featuredDataPoints = [];
        for (let fdp of fdps) {
            if (fdp.getDataTypeKey() == this.dataTypeKey) {
                this.featuredDataPoints.push(fdp);
            }
        }

        this.featuredDataPoints.sort(this.sortByOrder);

        // clone / keep original record
        this._featuredDataPoints = Object.create(this.featuredDataPoints);

        //this.rmSelectedFromFeatured();
        this.updateFeaturedDataPoints();
    }

    label: any;
    labelText: string;

    @Input() set dataTypeLabel(l: any) {
        this.label = l;
        this.getLabelText('en');
    }

    relatedDataPoints: RelatedDataPoint[] = [];

    /*@Input() set allRelatedDataPoints(rdps: RelatedDataPoint[]) {
        this.relatedDataPoints = [];
        this.selectedDataPoints = [];

        if (rdps) {
            for (let rdp of rdps) {
                if (rdp.getDataTypeKey() == this.dataTypeKey) {
                    this.relatedDataPoints.push(rdp);
                    if (!this.duplicatesAllowed) {
                        // dont select a data point if duplicates are allowed
                        this.selectedDataPoints.push(rdp.getDataPoint());
                    }

                    // if the sub data point is a company check if the name exists
                    // we use this to check if the company should be invited or not
                    if (this.subDataTypeKey == 'company') {
                        this.getCompanyExists(rdp.getSubDataPoint().getValue(), rdp);
                    }
                }
            }
            this.relatedDataPointCount.emit(this.relatedDataPoints.length);
        }
        //console.log('related DPS', this.relatedDataPoints);
        //console.log('selected', this.selectedDataPoints);

        //this.rmSelectedFromFeatured();
        this.updateFeaturedDataPoints();
    }*/

    // used in data-point-autocomplete in template
    @Input() showAllSuggestions: boolean = false;
    @Input() filterSuggestionMinChars: number = 1;
    @Input() canAddDataPoint: boolean = false;
    @Input() singleSelect: boolean = false; // if true, can only select one data point
    @Input() duplicatesAllowed: boolean = false; // if true, does not remember the selection and can select the same data point multiple times
    @Input() dataPointCount: number;
    @Input() placeholder: string = "Add...";
    @Input() sortBy: string;
    @Input() limitResults: number;
    //

    @Input() showLabel: boolean = true;
    @Input() showFeaturedInline: boolean = true;

    @Input() showRelatedSubDataType: boolean = false;
    @Input() showRelatedExperience: boolean = false;
    @Input() showRelatedStartDate: boolean = false;
    @Input() showRelatedEndDate: boolean = false;

    @Output() completed = new EventEmitter<number>();
    @Output() relatedDataPointCount = new EventEmitter<number>();
    @Output() newJobKey = new EventEmitter<string>();
    @Output() selectedDataPoint = new EventEmitter<DataPoint>();

    rating = {
        1: 'Beginner',
        2: 'Intermediate',
        3: 'Advanced',
        4: 'Professional',
        5: 'Expert'
    }

    dataPoints: DataPoint[];
    dataPointsForStaff: DataPoint[] = [];
    selectedDataPoints: (DataPoint | null)[] = [];
    selectedDataPointsByStaff: DataPoint[] = [];

    dataTypes: DataType[] = [];

    _featuredDataPoints: FeaturedDataPoint[] = []; // original clone
    featuredDataPoints: FeaturedDataPoint[] = [];

    selectedDpToUpdate: DataPoint | null;

    loading: boolean = false;

    sugStaff: string = '';

    constructor(
        private accountService: AccountService,
        private companyService: CompanyService,
        private alertService: AlertService,
        private collectionService: CollectionService,
    ) { }

    ngOnInit(): void {
        this.accountUser = this.accountService.loggedInAccountUserSig();
    }

    isStaff() {
        if (this.accountUser && (this.accountUser.isAdmin() || this.accountUser.isManager())) {
            return true;
        }
        return false;
    }

    /*getDataTypeLabel(key: string, who: string = 'profile') {
        const dataType = this.dataTypes.find(dt => dt['key'] == key);
        if (dataType && dataType['label'] && dataType['label'][who])
            return dataType['label'][who];

        else if(dataType && dataType['label'])
            return dataType['label'];

        else
            return '';
    }*/

    acStartTime: any;
    acElapsedTime: any;

    autoCompleteDataPointsForStaff() {

        this.acStartTime = Date.now();

        // check time between keystrokes, if more than 800 milisec, get data points
        setTimeout(() => {
            this.acElapsedTime = Date.now() - this.acStartTime;
            if (this.acElapsedTime >= 800) {
                this.acStartTime = 0;
                this.acElapsedTime = 0;

                this.getDataPointsForStaff(this.sugStaff);
            }
        }, 800);

    }

    updateDataPoint(dataPoint: DataPoint | null) {
        this.updateDPModal.open();
        this.selectedDpToUpdate = dataPoint;
    }

    // TODO fix me
    canEdit() {
        if (this._editing)
            return true;
        return false;
    }

    isEmpty(obj: Record<string, any>): boolean {
        if (obj === null || obj === undefined) {
            return true;
        }
        return Object.keys(obj || {}).length === 0;
    }

    /**
     * Format
     * {
        "key": "business_function",
        "label": {
            "en": {
                "editing": "What are your preferred business functions?",
                "viewing": "Preferred business function"
            },
            "sv": {
                "editing": "Vilka funktioner på ett företag vill du jobba med?",
                "viewing": "Föredragna affärsfunktioner"
            }
        }
    }
    */
    getLabelText(locale: string = 'en') {
        if (this.label) {
            if (this._editing) {
                try {
                    this.labelText = this.label[locale]['editing'];
                } catch {
                    this.labelText = '';
                }
            } else {
                try {
                    this.labelText = this.label[locale]['viewing'];
                } catch {
                    this.labelText = '';
                }
            }
        }
    }

    getFullYear(dateString: string) {
        if (dateString) {
            let d = new Date(dateString);
            return d.getFullYear();
        }
        return null;
    }

    // check if the company name exists and set the pageSlug property on the related data point
    getCompanyExists(name: string, rdp: RelatedDataPoint) {
        this.companyService.getPublicCompanyPageExists(name).subscribe(
            data => {
                if (data && data.hasOwnProperty('slug') && data['slug'])
                    rdp.pageSlug = data['slug'];
                else
                    rdp.pageSlug = '';
            },
            error => {
                rdp.pageSlug = '';
            }
        );
    }

    getDataPoint(rdp: RelatedDataPoint) {
        return rdp.getDataPoint();
    }

    getDataPointValue(rdp: RelatedDataPoint) {
        if (rdp.getDataPoint()) {
            return rdp.getDataPoint()?.getValue();
        }
        return null;
        //return rdp.getDataPoint['value']['en'];
    }

    getSubDataPoint(rdp: RelatedDataPoint) {
        if (!this.isEmpty(rdp.getSubDataPoint())) {
            return rdp.getSubDataPoint();
        }
        return null;
    }

    getSubDataPointValue(rdp: RelatedDataPoint) {
        if (!this.isEmpty(rdp.getSubDataPoint())) {
            return rdp.getSubDataPoint()['value']['en'];
        }
        return null;
    }

    getRelatedDataPoints() {
        //if (this.jobKey || this.accountUserId) {
            this.collectionService.getRelatedDataPoints(this.dataTypeKey, this.accountUserId, this.companyId, this.jobKey, this.projectId, this.hubId).subscribe(
                rdps => {
                    this.relatedDataPoints = [];
                    this.selectedDataPoints = [];

                    for (let rdp of rdps) {
                        if (rdp.getDataTypeKey() == this.dataTypeKey) {
                            this.relatedDataPoints.push(rdp);
                            if (!this.duplicatesAllowed) {
                                // dont select a data point if duplicates are allowed
                                this.selectedDataPoints.push(rdp.getDataPoint());
                            }

                            // if the sub data point is a company check if the name exists
                            // we use this to check if the company should be invited or not
                            if (this.subDataTypeKey == 'company') {
                                if (rdp.getSubDataPoint() && rdp.getSubDataPoint()['value'] && rdp.getSubDataPoint()['value']['en']) {
                                    this.getCompanyExists(rdp.getSubDataPoint()['value']['en'], rdp);
                                }
                            }
                        }

                    }
                    this.relatedDataPointCount.emit(this.relatedDataPoints.length);

                    this.updateFeaturedDataPoints();
                },
                error => {
                    console.log("Error fetching dps");
                }
            );
        //}
    }

    getDataPointsForStaff(autoCompText?: string) {
        this.loading = true;

        this.collectionService.getDataPoints(this.dataTypeKey, this.sortBy, 0, autoCompText, false).subscribe(
            dps => {
                this.dataPointsForStaff = dps;
                this.selectedDataPointsByStaff = [];

                this.loading = false;
            },
            error => {
                console.log("Error fetching dps");
            }
        );
    }

    getDataTypes() {
        this.collectionService.getDataTypes().subscribe(
            dts => {
                this.dataTypes = dts;
            },
            error => {
                console.log("Error fetching dts");
            }
        );
    }

    // field can be 'start_date' or 'end_date'
    updateRdpDate(rdpId: number, field: string, event: any) {
        if (event.target.value == '') {
            let data = {
                'id': rdpId,
            };

            if (field == 'start_date')
                data['start_date'] = null;

            if (field == 'end_date')
                data['end_date'] = null;

            this.updateRelatedDataPoint(data, false);

        } else if (event.target.value) {
            let dNum = Date.parse(event.target.value);
            let d = new Date(dNum);

            let data = {
                'id': rdpId,
            };

            if (field == 'start_date')
                data['start_date'] = d.toISOString().slice(0, 10);

            if (field == 'end_date')
                data['end_date'] = d.toISOString().slice(0, 10);

            this.updateRelatedDataPoint(data, false);
        }
    }

    updateRdpExperience(rdpId: number, experience: number) {
        let data = {
            'id': rdpId,
            'experience': experience
        };
        this.updateRelatedDataPoint(data);
    }

    updateRdpSubDataPoint(rdpId: number, dataPoint: DataPoint) {
        let data = {
            'id': rdpId,
            'sub_data_point_id': dataPoint.getId(),
            'sub_data_type_key': this.subDataTypeKey,
        };
        this.updateRelatedDataPoint(data);
    }

    updateRelatedDataPoint(data: any, reload: boolean = true) {
        /*let data = {
            'id': rdpId,
            'sub_data_point_id': null,
            'sub_data_type_key': null,
            'start_date': null,
            'end_date': null,
            'experience': experience
        };*/

        this.collectionService.updateRelatedDataPoint(data).subscribe(
            rdps => {
                if (reload)
                    this.getRelatedDataPoints();
            },
            error => {
                console.log("Error fetching dps");
            }
        );
    }

    /*
    getSelectedDataPoints() {
        this.collectionService.getSelectedDataPoints(this.dataTypeKey, this.accountUserId, this.companyId, this.jobId, this.projectId).subscribe(
            dps => {
                this.selectedDataPoints = dps;

                //console.log("selected dps", dps);

                this.updateFeaturedDataPoints();

            },
            error => {
                console.log("Error fetching dps");
            }
        );
    }
    */

    updateFeaturedDataPoints() {
        this.featuredDataPoints = Object.create(this._featuredDataPoints); // clone without reference
        this.rmSelectedFromFeatured();
    }

    // remove selected DataPoints from featured DataPoints
    rmSelectedFromFeatured() {
        for (let dp of this.selectedDataPoints) {
            const index = this.featuredDataPoints.findIndex(fdp => fdp.getDataPointId() == dp?.getId());
            if (index > -1) {
                this.featuredDataPoints.splice(index, 1);
            }
        }
    }

    emitJobKey(jobKey: string) {
        this.newJobKey.emit(jobKey);
    }

    addDataPoint(jobKey: string) {
        //console.log("emitJobKey triggered, jobKey:", jobKey);
        this.emitJobKey(jobKey);
        this.getRelatedDataPoints();
    }

    createDataPoint() {
        this.loading = true;

        this.collectionService.addDataPoint(this.sugStaff, this.dataTypeKey, this.accountUserId, this.companyId, this.jobPost, this.jobKey, this.projectId, this.hubId, this.singleSelect).subscribe(
            data => {
                this.loading = false;
            },
            error => {
                console.log("Error add");
            }
        );
    }

    /*toggleDataPoint(id: number) {
        this.collectionService.toggleDataPoint(id, this.dataTypeKey, this.accountUserId, this.companyId, this.jobPost, this.jobKey, this.projectId, this.singleSelect).subscribe(
            data => {
                if(data['job_key']) {
                    //this.jobId = data['job_id'];
                    this.jobKey = data['job_key'];
                    this.emitJobKey(data['job_key']);
                }

                this.getRelatedDataPoints();
                this.completed.emit(data);
            },
            error => {
                console.log("Error toggle");
            }
        );
    }*/

    /*toggleSubDataPoint(id: number) {
        this.collectionService.toggleDataPoint(id, this.subDataTypeKey, this.accountUserId, this.companyId, this.jobPost, this.jobKey, this.projectId, this.singleSelect).subscribe(
            data => {
                this.getRelatedDataPoints();
                this.completed.emit(data);
            },
            error => {
                console.log("Error toggle");
            }
        );
    }*/


    addInstantRdp(dp: DataPoint) {
        this.loading = true;

        if (this.singleSelect) {
            this.relatedDataPoints = [];
        }

        // if user_type, emit the data point to parent
        if (this.dataTypeKey == 'user_type') {
            this.selectedDataPoint.emit(dp);
        }

        let rdp = new RelatedDataPoint();
        rdp.data_point = dp;
        rdp.data_type = dp.getDataTypeId();
        this.relatedDataPoints.push(rdp);
        this.relatedDataPoints.sort(this.sortByDataPointValue);
    }


    // check duplicatesAllowed before creating rdp
    addRelatedDataPoint(dp: DataPoint) {

        //console.log(dp);

        this.loading = true;

        if (this.singleSelect) {
            this.relatedDataPoints = [];
        }

        if (this.duplicatesAllowed) {
            this.addInstantRdp(dp);
            this.createRelatedDataPoint(dp.getId());

        } else {
            const selected = this.selectedDataPoints.findIndex(sdp => sdp?.getId() == dp.getId());
            // if not selected, else dont create duplicate
            if (selected == -1) {
                this.addInstantRdp(dp);
                this.createRelatedDataPoint(dp.getId());
            }

        }

    }

    // call via addRelatedDataPoint to handle duplicates
    createRelatedDataPoint(dpId: number) {
        this.loading = true;

        this.collectionService.createRelatedDataPoint(dpId, this.dataTypeKey, this.accountUserId, this.companyId, this.jobPost, this.jobKey, this.projectId, this.hubId, this.singleSelect).subscribe(
            data => {
                if(data['job_key']) {
                    this.jobKey = data['job_key'];
                    this.emitJobKey(data['job_key']);
                }

                this.getRelatedDataPoints();
                this.completed.emit(data);
                this.loading = false;
            },
            error => {
                // failed to add rdp, remove the instantly added rdp
                const index = this.relatedDataPoints.findIndex(rdp => rdp.getDataPointId() == dpId);
                if (index > -1) {
                    this.relatedDataPoints.splice(index, 1);
                }
                this.alertService.error("Failed to add data, please try again");
                this.loading = false;
            }
        );
    }

    removeRelatedDataPoint(id: number) {
        if (!id) {
            return;
        }

        if (confirm("Are you sure you want to remove this data?")) {

            // instantly remove rdp
            const index = this.relatedDataPoints.findIndex(rdp => rdp.getId() == id);
            if (index > -1) {
                this.relatedDataPoints.splice(index, 1);
            }

            this.collectionService.removeRelatedDataPoint(id, this.jobKey, this.companyId, this.hubId).subscribe(
                data => {
                    if(data['job_key']) {
                        this.jobKey = data['job_key'];
                        this.emitJobKey(data['job_key']);
                    }

                    this.getRelatedDataPoints();
                    this.completed.emit(data);
                },
                error => {
                    this.alertService.error("Failed to remove the data, please try again");
                }
            );

        }
    }

    sortByOrder( a, b ) {
        if ( a.getOrder() < b.getOrder() ){
            return -1;
        }
        if ( a.getOrder() > b.getOrder() ){
            return 1;
        }
        return 0;
    }

    sortByDataPointValue( a, b ) {
        if ( a.getDataPointValue() < b.getDataPointValue() ){
            return -1;
        }
        if ( a.getDataPointValue() > b.getDataPointValue() ){
            return 1;
        }
        return 0;
    }

    openDataPointStaffModal() {
        this.getDataPointsForStaff();
        this.dpModal.open();
    }

    openDataTypeStaffModal() {
        this.getDataTypes();
        if (this.selectedDataPointsByStaff.length > 0) {
            this.editDataTypeModal.open();
        } else {
            this.alertService.error("Please select at least one data point");
        }
    }

    toggleToMergeDataPoint(dp: DataPoint) {
        // add dp to merge list selectedDataPointsByStaff if its not in the list, else remove it from the list
        const found = this.selectedDataPointsByStaff.find(sdp => sdp.getId() == dp.getId())
        if (!found) {
            this.selectedDataPointsByStaff.push(dp);
        }
        else {
            const index = this.selectedDataPointsByStaff.findIndex(sdp => sdp.getId() == dp.getId());
            if (index > -1) {
                this.selectedDataPointsByStaff.splice(index, 1);
            }
        }
    }

    mergeSelectedDataPoints() {
        // get a list of ids of selectedDataPointsByStaff and send it to the backend

        let ids: number[] = [];
        for (let dp of this.selectedDataPointsByStaff) {
            ids.push(dp.getId());
        }

        if (!this.isStaff()) {
            return;
        }

        let data = {
            'data_point_ids': ids,
        }

        if (confirm("Are you sure you want to merge these data points?")) {

            this.collectionService.mergeDataPoints(data).subscribe(
                data => {
                    this.getDataPointsForStaff();
                    this.alertService.success('Data points merged successfully');
                },
                error => {
                    this.alertService.warning("Error merging data points: " + error.error.error);
                }
            );

        }

    }

    removeDataTypeFromDataPoints(dt: DataType) {

        let ids: number[] = [];
        for (let dp of this.selectedDataPointsByStaff) {
            ids.push(dp.getId());
        }

        if (!this.isStaff()) {
            return;
        }

        let data = {
            'data_point_ids': ids,
            'data_type_id': dt.getId(),
        }

        if (confirm("Are you sure you want to remove this data type from all selected data points?")) {

            this.collectionService.removeDataTypeFromDataPoints(data).subscribe(
                data => {
                    //this.getDataPointsForStaff();
                    //this.editDataTypeModal.close();
                    this.alertService.success('Data type removed from the selected data points successfully');
                },
                error => {
                    this.alertService.warning("Error removing data type from selected data points: " + error.error.error);
                }
            );

        }
    }

    addDataTypeToDataPoints(dt: DataType) {

        let ids: number[] = [];
        for (let dp of this.selectedDataPointsByStaff) {
            ids.push(dp.getId());
        }

        if (!this.isStaff()) {
            return;
        }

        let data = {
            'data_point_ids': ids,
            'data_type_id': dt.getId(),
        }

        if (confirm("Add this data type to all selected data points?")) {

            this.collectionService.addDataTypeToDataPoints(data).subscribe(
                data => {
                    //this.getDataPointsForStaff();
                    //this.editDataTypeModal.close();
                    this.alertService.success('Data type added to the selected data points successfully');
                },
                error => {
                    this.alertService.warning("Error adding data type to the selected data points: " + error.error.error);
                }
            );

        }
    }
}
