<form [formGroup]="form">

    <div class="mb-3">
        <label for="key" class="block text-sm font-medium leading-6 text-gray-900">Value (en)</label>
        <div class="mt-2">
            <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input type="text" formControlName="value_en" class="w-full block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6">
            </div>
        </div>
    </div>

    <div class="mb-3">
        <label for="verified" class="block text-sm font-medium leading-6 text-gray-900">Verified</label>
        <div class="mt-2">
            <select formControlName="verified" class="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                <option [ngValue]="true">Yes</option>
                <option [ngValue]="false">No</option>
            </select>
        </div>
    </div>

    <div class="mb-3">

        <div class="my-2 text-sm font-medium leading-6 text-gray-900">
            Connected to Data Types:
        </div>

        <ul role="list" class="divide-y divide-gray-100">
            <li *ngFor="let dt of dataTypes" class="flex justify-between items-center gap-x-6 px-2 py-3 hover:bg-gray-50">
                <div class="flex gap-x-4">
                    <div class="min-w-0 flex-auto">
                        <p class="text-sm text-gray-900">
                            {{ dt.getKey() }}
                        </p>
                    </div>
                </div>
                <div class="flex items-center gap-x-4">
                    <div class="flex flex-col items-end">
                        <span class="btn-blue-sm" (click)="removeDataType(dt.getId())"><i class="bi bi-trash"></i></span>
                    </div>
                </div>
            </li>
        </ul>

    </div>

    <button class="btn-blue" (click)="update()">Update</button>

</form>
