import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';

import { CollectionService } from '../../_services/collection.service';
import { CompanyService } from '../../_services/company.service';
import { FooterService } from '../../_services/footer.service';

import { DataPoint, FeaturedDataPoint } from '../../_models/collection';

@Component({
  selector: 'app-data-point-autocomplete',
  templateUrl: './data-point-autocomplete.component.html',
  styleUrls: ['./data-point-autocomplete.component.scss']
})
export class DataPointAutocompleteComponent implements OnInit {

    @Input() dataTypeKey: string;
    @Input() parentDataTypeKey: string;

    @Input() showAllSuggestions: boolean = false;
    @Input() filterSuggestionMinChars: number = 1;
    @Input() canAddDataPoint: boolean = false;
    @Input() singleSelect: boolean = false;
    @Input() dataPointCount: number;
    @Input() showAddInput: boolean = true;
    @Input() placeholder: string = "Add...";
    @Input() sortBy: string;
    @Input() limitResults: number;

    @Input() set allFeaturedDataPoints(dps: FeaturedDataPoint[]) {
        this.featuredDataPoints = dps;
        this.updateAddInput();
    }

    @Input() set allSelectedDataPoints(dps: (DataPoint | null)[]) {
        this.selectedDataPoints = dps;
    }

    // related
    @Input() accountUserId: number | undefined | null;
    @Input() companyId: number;
    @Input() jobPost: boolean = false;
    @Input() jobKey: string | null;
    @Input() projectId: number;
    @Input() hubId: number;

    @Output() dataPointAdded = new EventEmitter<DataPoint>();
    @Output() newJobKey = new EventEmitter<string>();
    @Output() triggerToggle = new EventEmitter<DataPoint>();
    @Output() outputDataPoints = new EventEmitter<DataPoint[]>();

    sug: string = '';
    languageCode: string = 'en';

    dataPoints: DataPoint[] = [];
    filterDataPoints: DataPoint[] = [];
    featuredDataPoints: FeaturedDataPoint[] = [];
    selectedDataPoints: (DataPoint | null)[] = [];

    loading: boolean = false;

    constructor(
        private companyService: CompanyService,
        private collectionService: CollectionService,
        private footerService: FooterService,
    ) { }

    ngOnInit(): void {
    }

    showMobileFooter(show: boolean) {
        // display footer
        this.footerService.showMobile.set(show);
    }

    closeSuggestions(event) {

        if (event.path && this.filterDataPoints && this.filterDataPoints.length > 0)
            if (event.path[0] && event.path[0].className != 'dropdown' &&
                event.path[1] && event.path[1].className != 'dropdown' &&
                event.path[2] && event.path[2].className != 'dropdown' &&
                event.path[3] && event.path[3].className != 'dropdown' &&
                !event.path[0].className.includes('add-item')
               ) {
                   this.closePreviewSuggestions();
            }
    }

    openPreviewSuggestions() {
        if (this.dataPoints && this.filterDataPoints && this.filterDataPoints.length == 0) {
            if (this.showAllSuggestions || +this.limitResults == 0) {
                this.filterDataPoints = this.dataPoints;
            } else {
                this.filterDataPoints = this.dataPoints.slice(0, 10);
            }
        }
    }

    closePreviewSuggestions() {
        this.filterDataPoints = [];
        this.sug = '';
    }

    /*sortByValue( a, b ) {
        if ( a.value['en'] < b.value['en'] ){
            return -1;
        }
        if ( a.value['en'] > b.value['en'] ){
            return 1;
        }
        return 0;
    }*/

    //@HostListener('document:touchend', ['$event'])
    //@HostListener('document:click', ['$event'])
    @HostListener('document:mousedown', ['$event'])
    onCloseSuggestion(event): void {
        this.closeSuggestions(event);
    }


    acStartTime: any;
    acElapsedTime: any;

    autoCompleteDataPoints() {

        this.acStartTime = Date.now();

        // check time between keystrokes, if more than 800 milisec, get data points
        setTimeout(() => {
            this.acElapsedTime = Date.now() - this.acStartTime;
            if (this.acElapsedTime >= 800) {
                this.acStartTime = 0;
                this.acElapsedTime = 0;

                this.getDataPoints(this.sug);
            }
        }, 800);

    }

    loadDataPoints() {
        if (!this.dataPoints || (this.dataPoints && this.dataPoints.length == 0)) {
            this.getDataPoints();
        }
    }

    // data points are fetched on open auto complete dropdown
    getDataPoints(autoCompText?: string) {
        this.loading = true;

        this.collectionService.getDataPoints(this.dataTypeKey, this.sortBy, this.limitResults, autoCompText).subscribe(
            dps => {
                this.dataPoints = dps;
                //this.outputDataPoints.emit(dps);

                this.filterSuggestions();

                this.openPreviewSuggestions();

                this.rmFeaturedFromFiltered();

                this.updateAddInput();

                this.loading = false;
            },
            error => {
                console.log("Error fetching dps");
            }
        );
    }

    addDataPoint() {
        this.loading = true;

        // if user is adding a company, check if company exists
        // if not, create empty company. Companies where the name is
        // the same as the data point are automatically linked in
        // data-point-select.component.ts
        if (this.dataTypeKey == 'company') {
            this.companyService.createEmpty(this.sug, '').subscribe(
                data => {
                    let slug = data['slug'];
                    let webAddress = data['web_address'];
                },
                error => {
                    console.log("Error create");
                }
            );
        }

        // add data point
        this.collectionService.addDataPoint(this.sug, this.dataTypeKey, this.accountUserId, this.companyId, this.jobPost, this.jobKey, this.projectId, this.hubId, this.singleSelect).subscribe(
            data => {
                const d = new DataPoint().deserialize(data);
                const found = this.dataPoints.find(dp => dp.getId() == d.getId())
                if (!found) {
                    this.selectedDataPoints.push(d);
                }
                this.dataPointAdded.emit(d);

                if(data['job_key']) {
                    this.jobKey = data['job_key'];
                    this.newJobKey.emit(data['job_key']);
                }

                this.sug = '';
                this.loading = false;
                this.closePreviewSuggestions();
            },
            error => {
                console.log("Error add");
            }
        );
    }

    triggerToggleDataPoint(dp: DataPoint) {
        this.triggerToggle.emit(dp);
        this.closePreviewSuggestions();
        this.updateAddInput();
    }

    // only add a data point if no filtered dps match
    onEnter(event) {
        event.preventDefault(); // prevent any buttons next to the input to be clicked on enter

        if (this.canAddDataPoint && this.sug.length > 0 && !this.isInputMatchingAFilteredDataPoint()) {
            this.addDataPoint();
        }
    }

    filterSuggestions() {
        if (this.sug == '') {
            this.filterDataPoints = [];

        } else {
            if (this.sug.length >= this.filterSuggestionMinChars) {
                if (this.dataPoints) {
                    this.filterDataPoints = this.searchFromArray(this.dataPoints, this.sug);
                }
            }
        }
    }

    searchFromArray(arr, text) {
        let matches: any[] = [], i;
        for (i = 0; i < arr.length; i++) {
            let val = arr[i].value['en'];
            // if only one letter look for exact match
            if (text.length == 1) {
                if (val.toLowerCase() == text.toLowerCase())
                    matches.push(arr[i]);

            // if more that one letter look for the pattern
            } else {
                if (val && text && val.toLowerCase().includes(text.toLowerCase()))
                    matches.push(arr[i]);
            }
        }
        return matches;
    };

    // remove featured DataPoints from filtered DataPoints
    rmFeaturedFromFiltered() {
        for (let dp of this.featuredDataPoints) {
            const index = this.filterDataPoints.findIndex(fdp => fdp.getId() == dp.getId());
            if (index > -1) {
                this.filterDataPoints.splice(index, 1);
            }
        }
    }

    updateAddInput() {
        // visibility
        if (!this.canAddDataPoint && this.dataPointCount == this.featuredDataPoints.length) {
            this.showAddInput = false;
        }

        // placeholder
        // dont change if Input is something else
        if (this.placeholder == "Add...") {
            if (this.featuredDataPoints.length > 0 && this.dataPointCount > this.featuredDataPoints.length) {
                this.placeholder = "Other...";
            }
        }

        if (this.parentDataTypeKey == 'licenses_certifications') {
            this.placeholder = "Add issuing organization...";

        } else if (this.dataTypeKey == 'company') {
            this.placeholder = "Add company...";

        } else if (this.singleSelect && this.selectedDataPoints.length > 0) {
            this.placeholder = "Edit";
        } else {
            this.placeholder = "Add...";
        }
    }

    isInputMatchingAFilteredDataPoint() {
        // look through all values in all languages
        const found = this.filterDataPoints.find(dp => dp.getValues().find(v => v.toLowerCase() == this.sug.toLowerCase()))
        if (found) {
            // set language code depending on the what the user typed in sug
            const entry = Object.entries(found.value as { [key: string]: string }).find(
              ([key, value]) => value.toLowerCase() === this.sug.toLowerCase()
            );
            this.languageCode = entry ? entry[0] : 'en';
            return true;
        }
        return false;
    }

    isSelected(dp: DataPoint) {
        if (this.selectedDataPoints) {
            const found = this.selectedDataPoints.find(sdp => sdp?.getId() == dp.getId())
            if (found) {
                return true;
            }
        }
        return false;
    }


}
